import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

let emssData;
let icligmData;
let saasData;
let cloudData;
let iclreactData="";
let iclCBData="";

const About = () => {
  const [data, setData] = useState({ paragraph: "Loading...", Why: [] });
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
  
    fetch(`https://servguest.eximon.com/ServConI?page=INDEX&timestamp=${encodeURIComponent(new Date().getTime())}`)
      .then((response) => response.text())
      .then((fetchedData) => {
        const lines = fetchedData.split('\n');
        let aboutData = "About data not found";

        for (let line of lines) {
          if (line.trim().startsWith("aboutus")) {
            aboutData = line.trim().substring(7);
//            break;
          }
          if (line.trim().startsWith("remss")) {
            emssData = line.trim().substring(5);
          }
          if (line.trim().startsWith("ricligm")) {
            icligmData = line.trim().substring(7);     
          }
          if (line.trim().startsWith("rsaas")) {
            saasData = line.trim().substring(5);     
          }          
          if (line.trim().startsWith("rcloud")) {
            cloudData = line.trim().substring(6);     
          }
          if (line.trim().startsWith("riclreact")) {
            iclreactData = line.trim().substring(9);  
          }                    
          if (line.trim().startsWith("riclchatbot")) {
            iclCBData = line.trim().substring(11);  
          }                              
        }
//        console.log("About Data:", aboutData); console.log("EMSS Data:", emssData); console.log("ICLIGM Data:", icligmData); console.log("SAAS Data:", saasData); console.log("Cloud Data:", cloudData); console.log("ICLReact Data:", iclreactData);
        setData((prevData) => ({ ...prevData, paragraph: aboutData }));
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);
   
  // Function to toggle the "Read more" state
  const toggleReadMore = () => {
    const paragraphs = document.getElementById('about').getElementsByTagName('p');
    const aboutParagraph = paragraphs[maxLines];
    
    if (expanded) {
      // If currently expanded, scroll to the top of the container
      document.getElementById('about').scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else {
      // If not expanded, scroll to the top of the expanded content
      aboutParagraph.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  for (let i = maxLines; i < paragraphs.length; i++) {
    paragraphs[i].style.display = expanded ? 'none' : 'block';
  }
  setExpanded(!expanded);
  };

  // Function to create an HTML element from the string
  const createMarkup = (htmlString) => ({ __html: htmlString });

  const maxLines = 2; // The maximum number of lines to display

  // Function to limit the number of lines displayed
  const truncateText = (text) => {
    if (text) {
      const div = document.createElement('div');
      div.innerHTML = text;
  
      const paragraphs = div.getElementsByTagName('p');
      if (paragraphs.length > maxLines) {
        for (let i = maxLines; i < paragraphs.length; i++) {
          paragraphs[i].style.display = 'none';
        }
      }
  
      return div.innerHTML;
    }
    return text;
  };

  return ( 
    <div id="about">
            <Helmet>
      <meta charSet="utf-8" lang="en" />
      {/* <title>About us</title> */}
      <link rel="canonical" href="https://eximon.com/#about" />
      <meta name="description" content="Founded in 1992 Immortal Computer Lab Pvt. Ltd. (ICL) is a family-owned software company" />
      <meta name="keywords" content="Enterprise Software Solution for Maritime, Maritime Shipping Companies Software, Shipping Agencies Software, Freight Forwarders Software, NVOCC Software Solutions, Liner Software Solutions, ICLIGM Software, Maritime Enterprise Solutions, Shipping Management Software, Freight Forwarding Solutions, NVOCC Business Solutions, Liner Management Software,Enterprise Exim Management Software, Export-Import Trade Solutions, Integrated Trade Management Software, Exim Solutions for Manufacturers, Corporate Trade Management Software, MNC Trade Management Solutions, Merchant-Exporter Software Solutions, SME Trade Management Software, Exim Management for Corporates, Enterprise Export-Import Solutions, Trade Management for MNCs, Software Solutions for Exporters, Import Trade Management Software, Enterprise Trade Solutions, Exim Software for SMEs" />
      <meta property="og:title" content="About us" />
    </Helmet>
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <img src="img/about.jpg" className="img-responsive" alt="" />
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h1>About us</h1>
  
              {/* Render limited lines with "Read more" link */}
              <div
                className={`about-paragraph ${expanded ? 'expanded' : 'truncated'}`}
                dangerouslySetInnerHTML={createMarkup(
                  expanded ? `${data.paragraph}  <a href="#privpol" style="float: right;"><h4>Privacy Policy</h4></a>` : truncateText(data.paragraph)
                )}
              />
              {data.paragraph.length > maxLines && (
                <button onClick={toggleReadMore} className="btn-custom">
                  {expanded ? 'Read less' : 'Read more'}
                </button>
              )}

              <div className="list-style">
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {data.paragraph !== "Loading..." && data.Why.map((d, i) => (
                      <li key={`${d}-${i}`}>{d}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export { emssData, icligmData, saasData, cloudData, iclreactData, iclCBData};
export default About;
