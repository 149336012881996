import React, { useState } from "react";

export const Navigation = (props) => {
  const [isServicesDropdownOpen, setIsServicesDropdownOpen] = useState(false);
//  const [aboutusDropdown, setAboutusDropdown] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };
  const closeMenu = () => {
    setIsOpen(false);
  };

  const toggleServicesDropdown = () => {
    setIsServicesDropdownOpen(!isServicesDropdownOpen);
  };
  // const toggleAboutusDropdown = () => {
  //   setAboutusDropdown(!aboutusDropdown);
  // };

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
            onClick={handleToggle}
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <a className="navbar-brand page-scroll" href="#page-top">
            EXIMON
          </a>{" "}
        </div>

        <div className={`collapse navbar-collapse ${isOpen ? "" : "collapse"}`}
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a href="#features" className="page-scroll" onClick={closeMenu}>
                Features
              </a>
            </li>
            {/* <li className={`dropdown ${aboutusDropdown ? "open" : ""}`}
              onClick={toggleAboutusDropdown}>
              <a
                href="#about"
                className="page-scroll dropdown-toggle"
                data-toggle="dropdown" 
              >
                About us<b className="caret"></b>
              </a>
              <ul className="dropdown-menu" onClick={toggleAboutusDropdown}> */}
              <li>
                  <a href="#about" className="page-scroll" onClick={closeMenu}>About us</a>
                </li>
                {/* <li>
                  <a href="#PrivacyPolicy" >Privacy Policy</a>
                </li>            
            </ul>
            </li> */}
            <li
              className={`dropdown ${isServicesDropdownOpen ? "open" : ""}`}
              onClick={toggleServicesDropdown}
            >
              <a
                href="#services"
                className="page-scroll dropdown-toggle"
                data-toggle="dropdown" 
              >
                Services <b className="caret"></b>
              </a>
              <ul className="dropdown-menu" onClick={closeMenu}>
                <li>
                  <a href="#services" >EMSS (for Importers Exporters)</a>
                </li>
                <li>
                  <a href="#icligm" >ICLIGM (for Maritime Shipping companies)</a>
                </li>
                <li>
                  <a href="#iclchat" >ICL ChatBot (GenAI / ML)  </a>
                </li>
                <li>
                  <a href="#iclreact" >ICL Reacts (Mobile app)  </a>
                </li>                
              </ul>
            </li>
            <li>
              <a href="#portfolio" className="page-scroll" onClick={closeMenu}>
                Gallery
              </a>
            </li>
            <li>
              <a href="#testimonials" className="page-scroll"  onClick={closeMenu}>
                Testimonials
              </a>
            </li>
            <li>
              <a href="#team" className="page-scroll" onClick={closeMenu}>
                Team
              </a>
            </li>
            <li>
              <a href="#contact" className="page-scroll" onClick={closeMenu}>
                Contact
              </a>
            </li>                        
          </ul>
        </div>
      </div>
    </nav>
  );
};
