import React from "react";
import Modal  from "react-modal";
import {Helmet} from "react-helmet";

Modal.setAppElement("#root");

export const Iclreact = (props) => {
//  const [modalIsOpen,setModalIsOpen] = useState(false);
  // const [videoIsOpen,setVideoIsOpen] = useState(false);  
  // const [isLoading,setLoading] = useState(true);
//  const videoId='https://iclvideos.s3.ap-south-1.amazonaws.com/chatbots/Support_ChatBot.mp4'; ///to be updated

  return (  
  <div id="iclreact" className="text-center">
      <Helmet>
      <meta charSet="utf-8" lang="en" />
      
      <link rel="canonical" href="https://eximon.com/#iclreact" />
      <meta name="description" content="Access all essential software training resources for the ICL Software in one convenient place." />
      <meta name="keywords" content="Mobile App Development, App Design, App Development Company, Mobile App Builder, App Prototyping, Mobile UX/UI Design, App Store Optimization, Mobile App Marketing, App Testing, Mobile App Analytics, App Monetization, User Acquisition, Mobile App Security, App Maintenance, Cross-Platform Development" />
      <meta property="og:title" content="ICL Reacts" />
    </Helmet>

      <div className="container">
        <div className="section-title">
          <h1>ICL Reacts (Mobile App)</h1>
          <h2>Access all essential software training resources for the ICL Software in one convenient place.</h2>
          <div className="btn-group">
          <button onClick={() => window.open('https://tinyurl.com/eximon','_blank')} type="button" className="btn btn-custom btn-lg" >
                  Download 
                </button> 
        {/* <button onClick={()=>setVideoIsOpen(true)} type="button" className="btn btn-custom btn-lg" >
                  Demo
                </button>               
                <Modal 
        isOpen={videoIsOpen} 
        onRequestClose={() => setVideoIsOpen(false)}
        style={{
          content: {
            position: 'absolute',
            top: 'auto',
            left: '10px',
            right: 'auto',
            bottom: '20px',
            width: '70%',
            height: '60%'
          }
        }}
      >

        {isLoading && <div style={{fontWeight:"bold"}}>Please wait loading ...</div>}
        <iframe 
          title="ICL React Demo" 
          src={videoId}  
          allowFullScreen 
          style={{ width: '100%', height: '90%' }} onLoad={()=>setLoading(false)}
        />
        {!isLoading && 
                        <button onClick={()=>{setVideoIsOpen(false);setLoading(true);}} type="button" className="btn btn-custom btn-lg" >
                  Close
                </button> 
}               
        </Modal> */}
                </div>                
        </div>       
          <div className="row" style={{marginTop:'10px'}}>
          {
          props.data
            ? props.data.map((d, i) => (
                <div key={`${d.Title}-${i}`} className="col-md-4">
                  <div className="caption">
                    {d.Title && <h2>{d.Title}</h2>}
                    </div>
                    <div>
                    {d.Subtitle && <h3>{d.Subtitle}</h3>}
                    {/* {d.Paragraph} */}
                    {d.Paragraph.map((item, j) => {
          switch (item.type) {
            case "text":
              return <span key={j}>{item.content}</span>;
              case "list":
              return (
                <ul key={j}>
                  {item.items.map((listItem, k) => (
                    <li key={k} style={{textAlign:"left", marginBottom:"5px"}} dangerouslySetInnerHTML={{ __html: listItem }}></li>
                  ))}
                </ul>
              );             
            case "bold":
              return <strong key={j}>{item.content}</strong>;
            default:
              return null;
          }
        })}                    
                    </div>
                  </div>
                  ))
                :"loading"}
        </div>
      </div>
    </div>
    
  );
};
